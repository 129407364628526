import baseTheme  from '@lekoarts/gatsby-theme-cara/src/gatsby-plugin-theme-ui'

const theme = {
  ...baseTheme ,
  config: {
    initialColorModeName: `dark`,
    useColorSchemeMediaQuery: false
  },
  colors: {
    ...baseTheme .colors,
    background: `#FFE542`,
  },
}

export default theme